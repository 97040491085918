import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Navbar from "../components/Navbar";
import {
  StoryContainer,
  DescriptionContainer,
  Title,
  Paragraph,
  Highlight,
  Image,
  ImageContainer,
} from "../styles/StoryStyles";
import StoryIntro from "../components/StoryIntro";
import mango from "../images/m600x337.jpg";
import mango2 from "../images/m600x600.jpg";

const Project1 = () => (
  <Layout>
    <SEO title="Project 1 Test" />
    <Navbar />
    <StoryContainer>
      <StoryIntro
        image={mango}
        deployUrl=""
        repoUrl="https://github.com/mgc3"
        skills="Skills: React, Redux, Express, PostGreSQL, Sequelize, TypeScript,
      Javascript, Styled Components, Storybook, Jest"
      />
      <DescriptionContainer className="fade-in__second">
        <Title>Intro</Title>
        <Paragraph>
          Ooh, name it after me! I barely knew Philip, but as a clergyman I have
          no problem telling his most intimate friends all about him. Is that a
          cooking show? But existing is basically all I do! Who said that? SURE
          you can die!
        </Paragraph>
        <Title>Development</Title>
        <Paragraph>
          Ooh, name it after me! I barely knew Philip, but as a clergyman I have
          no problem telling his most intimate friends all about him. Is that a
          cooking show? But existing is basically all I do! Who said that? SURE
          you can die! You want to die?!{" "}
          <Highlight>Spare me your space age technobabble</Highlight>, Attila
          the Hun! For one beautiful night I knew what it was like to be a
          grandmother. Subjugated, yet honored. Oh, how awful. Did he at least
          die painlessly? …To shreds, you say. Well, how is his wife holding up?
          …To shreds, you say.
        </Paragraph>
        <ImageContainer>
          <Image src={mango2} className="fade-in__first" />
        </ImageContainer>
        <Paragraph>
          Ooh, name it after me! I barely knew Philip, but as a clergyman I have
          no problem telling his most intimate friends all about him. Is that a
          cooking show? But existing is basically all I do! Who said that? SURE
          you can die! You want to die?!{" "}
          <Highlight>Spare me your space age technobabble</Highlight>, Attila
          the Hun! For one beautiful night I knew what it was like to be a
          grandmother. Subjugated, yet honored. Oh, how awful. Did he at least
          die painlessly? …To shreds, you say. Well, how is his wife holding up?
          …To shreds, you say.
        </Paragraph>
      </DescriptionContainer>
    </StoryContainer>
  </Layout>
);

export default Project1;
